.mapBox {
    padding: 2.5%;
}

.navigationMap {
    margin: auto;
    width: 100%;
    height: 50vh;
}

.navAddressText {
    margin: auto;
    padding: 1% 20% 0 20%;
    width: 100%;
}

.addressHeading {
    margin: auto;
    padding: 5% 20% 0 20%;
    width: 100%;
}

.navButton {
    margin: auto;
    padding: 2% 20% 0 20%;
}